<template>
   <div class="card">
      <div class="card-body">
         <div class="d-flex justify-content-between align-items-center mb-2">
            <div>
               <h4 class="card-title">Hour Trend</h4>
            </div>
            <!-- <div class="w-25">
               <label class="mb-1">Question Type</label>
               <multiselect v-model="questionType" :close-on-select="false" :show-labels="false"
                  :options="getQuestionTypes" track-by="key" label="value" :multiple="true">
               </multiselect>
            </div> -->
         </div>
         <!-- Spline Area chart -->
         <VueApexCharts class="apex-charts" height="350" type="area" dir="ltr" :series="series" :options="chartOptions"
            :key="key"></VueApexCharts>
      </div>
   </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../mixins/filterData";
// import Multiselect from "vue-multiselect";

export default {
   props: ["filters"],
   mixins: [filterMixins],
   components: {
      VueApexCharts,
      // Multiselect
   },
   data() {
      return {
         params: {},
         responseData: [],
         title: "Live Registrations",
         series: [
            {
               name: "Views",
               data: [],
            },
            {
               name: "Viewers",
               data: [],
            },
         ],
         chart: {
            toolbar: {
               show: false,
            },
         },
         chartOptions: {
            dataLabels: {
               enabled: false,
            },
            stroke: {
               curve: "smooth",
               width: 3,
            },
            colors: ["#556ee6", "#34c38f"],
            xaxis: {
               type: "date",
               categories: ["Januaray"],
            },
         },
         key: 0,
         loading: true,
         questionType: [],
         getQuestionTypes: [
            {
               key: 'comments',
               value: 'Posts'
            },
            {
               key: 'mcq',
               value: 'MCQ'
            }
         ],
         hour: [],
      };
   },
   watch: {
      filters: {
         deep: true,
         handler() {
            this.getHourTrend();
         },
      },
      // "questionType"(v) {
      //    console.log(v);
      //    if (v == 'mcq') {
      //       console.log("hi mcq");
      //    }
      //    else if(v == 'comments') {
      //       console.log("hi comm");
      //    }
      // }
   },
   created() {
      // this.init();
   },
   methods: {
      init() {
         // this.loading = true;
         // this.loading = false;
      },
      async getHourTrend() {
         const searchfiltersParams = this.getCleanFilteredData(this.filters);
         try {
            const response = await this.$http.get(
               appConfig.api_base_url + "/cases/hourTrend",
               {
                  params: searchfiltersParams,
               }
            );
            //iteration
            //add filters 
            // condition1 - if not selected - show aggregated
            // condition 2 - selects 1 option - filter data 
            // condition 3 - selects all options - show aggregated
            this.hour = response.data.hour_trend;
            const compressed = this.hour.reduce((a, c) => {
               const dateIndex = a.findIndex(
                  (o) =>
                     o.hour === c.hour &&
                     (c.hour != null || c.hour != "null" || c.hour != "")
               );
               if (dateIndex !== -1) {
                  a[dateIndex].views += c.views;
                  a[dateIndex].viewers += c.viewers;
               } else {
                  a.push({
                     hour: c.hour,
                     views: c.views,
                     viewers: c.viewers,
                  });
               }
               return a;
            }, []);
            this.hour = compressed;
            let time = [];
            this.chartOptions.xaxis.categories = [];
            for (let index = 0; index < 24; index++) {
               this.chartOptions.xaxis.categories.push(
                  "" + index + ":00 - " + (parseInt(index) + 1) + ":00"
               );
               time.push(index);
            }
            this.series[0].data = [];
            this.series[1].data = [];
            time.forEach((c) => {
               let d = this.hour.filter((v) => v.hour == c);
               let views = d.length > 0 ? d[0].views : 0;
               let viewers = d.length > 0 ? d[0].viewers : 0;
               this.series[0].data.push(views);
               this.series[1].data.push(viewers);
            });
            this.key += 1;
         } catch (err) {
            console.log(err);
         }
      },

      // async getQuestionType() {
      //    try {
      //       const response = await this.$http.get(
      //          appConfig.api_base_url + "/cases/hourTrend",
      //       );
      //       const responseData = response.data.hour_trend;
      //    } catch(err) {
      //       console.log(err);
      //    }
      // }
   },
   mounted() {
      this.getHourTrend();
      // console.log(this.question_type);
   },
};
</script>
 